import React, { useState,useEffect  } from "react";
import { useTable } from "react-table";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import Confirmation from "../custom/confirmation-popup/ConfirmPopup";
import useAxios from "../../axiosinstance";
import { noteRefs } from "../../redux/actions";
import { useSelector,useDispatch } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import sortDropStyles from "./SortDropdown.module.css";
import Message from "../custom/toster/Message";
import InfiniteScroll from "react-infinite-scroll-component";
function OrganizationListTable({ orgData, updatePagination,totaldata,platFormData }) {

  
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );

  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [specialityName, setSpecialityName] = useState("");
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [rowType, setRowType] = useState("orgStausApprove");
  const [approveStatus, setApproveStatus] = useState("");
  const [orgId, setOrgId] = useState("");

  const actionData = {
    view_url: "org-view",
    edit_url: "edit-organization-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = orgData;

  const handleStatusChange = async (status, org_id) => {
    setDisplayConfirmationModal(true);
    setApproveStatus(status);
    setOrgId(org_id);
    console.log("status", status);
  }
   // Hide the modal
   const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const approveRequest = async () => {
    

    // console.log("Status organization--", approveStatus);
    // console.log("approveStatus", approveStatus);
    let s;
    // if(Number(approveStatus) === 1){
    //   s = -1
    // }else{
    //   s = 1
    // }
    const json = JSON.stringify({
      status: approveStatus,
    });
    let res = await axiosInstance.current.patch(
      `extorg/org/update_organization_status/${orgId}`,json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res)=>{
      if (approveStatus === 1) {
        setMessage("Organization Approved");
      } else if (approveStatus === -1) {
        setMessage("Organization Suspend");
      } else if (approveStatus === 0) {
        setMessage("Organization Pending");
      } else {
        setMessage("Organization Approved");
      }
      setMessageType("success");
      dispatch(noteRefs(new Date().getSeconds()));
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err)=>{
      if (approveStatus === 1) {
        setMessage("Organization Not Approved");
      } else if (approveStatus === -1) {
        setMessage("Organization Not Suspend");
      } else if (approveStatus === 0) {
        setMessage("Organization Not Pending");
      } else {
        setMessage("Organization Not Approved");
      }
      setMessageType("error");
    });
    setDisplayConfirmationModal(false);
  };
  useEffect(() => {
    // Update the speciality name when orgData changes
    if (orgData && orgData.length > 0) {
      const specName = orgData.map(org => org.specility.map(spec => spec.name).join(', ')).join('; ');
      setSpecialityName(specName);
    } else {
      setSpecialityName('N/A');
    }
  }, [orgData]);
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "1",
        headerClassName: "col-img",
        className: "col-img",
        accessor: "org_name",
        Cell: (row) => (
          <span>
            <label className="user-letter small">
              {row.value.charAt(0).toUpperCase()}
            </label>
          </span>
        ),
      },
      {
        Header: "Organization ID",
        accessor: "org_id",
      },
      {
        Header: "Organization Name",
        accessor: "org_name",
      },
      {
        Header: "Speciality Name",
        accessor: 'specility',
        Cell: ({ value }) => {
          console.log(value,"console.log(value);console.log(value);console.log(value);");
          if (value && value.length > 0) {
            return value.map((spec) => spec.name).join(', ');
          }
          return 'N/A';
        },

       
      },
      {
        Header: "Status",
        accessor: "status",
        headerClassName: "col-status",
        className: "col-status",
        Cell: (row) => (
          <div
            className={`custom_sort_dropdown ${sortDropStyles.sort_dropdown_main}`}
          >
            <Dropdown className={`custom_dropdown ${sortDropStyles.drop_down}`}>
              <Dropdown.Toggle
                className={sortDropStyles.dropdown_button}
                style={{paddingLeft: "0"}}
                variant="default"
                id="dropdown-basic"
              >
               <button
                style={{paddingLeft: "0"}}
                  className={`badge bdg_btn ${
                    row.row.original.status === 1
                      ? "badg_active"
                      : row.row.original.status === -1
                      ? "badg_inactive"
                      : row.row.original.status === 0
                      ? "badg_pending"
                      : "badg_unknown"
                  }`}
                >
                  {row.row.original.status === 1
                    ? "Approved"
                    : row.row.original.status === -1
                    ? "Suspended"
                    : row.row.original.status === 0
                    ? "Pending"
                    : "Unknown Status"}
                </button>
              </Dropdown.Toggle>

              <Dropdown.Menu className="mt-1 miku" >
              {
                  row.row.original.status === 1 ? (
                <Dropdown.Item href="" onClick={(e) => handleStatusChange(-1, row.row.original.org_id)}  > Suspend</Dropdown.Item>
                    
                  ):(
                    <Dropdown.Item href="" onClick={(e) => handleStatusChange(1, row.row.original.org_id)}  > Approve</Dropdown.Item>

                  )
                }
                {/* <Dropdown.Item href="" onClick={(e) => handleStatusChange(0, row.row.original.org_id)}  > Pending</Dropdown.Item> */}
                {row.row.original.status === 0 ? <Dropdown.Item href="" onClick={(e) => handleStatusChange(0, row.row.original.org_id)}  > Pending</Dropdown.Item> : "" }
                
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
            <TableActionItems
              viewAction="false"
              editAction="true"
              feedbackAction="false"
              deleteAction="true"
              addActionPopup="true"
              addActionHcpPopup="true"
              addActionPatientPopup="true"
              addSecurityAdmin="true"
              arrowAction="false"
              rowName={row.row.original.org_id}
              rowId={row.row.original.org_id}
              rowData={actionData}
              rowType="orgList"
              notetitle={row.row.original.ea_name}
              rowOrgId={row.row.original.org_name}
              specility={row.row?.original?.specility}
              orgId={row.row.original.org_id}
              orgListOrgName={row.row.original.lavel}
              platFormData={platFormData}
              platForm={row.row.original.platform}
              orgData={orgData}
            />
          </span>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

console.log("platFormDatapl2232323",platFormData)



  return (
    <>
    {message && <Message message={message} type={messageType} />}
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
          loader={ orgData.length >= 25 && orgData.length !== totaldata  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      <Confirmation
        showModal={displayConfirmationModal}
        confirmModal={approveRequest}
        hideModal={hideConfirmationModal}
        message={
          approveStatus === 1
            ? "Are you sure want to Approve?"
            : approveStatus === -1
            ? "Are you sure you want to Suspend?"
            : approveStatus === 0
            ? "Are you sure you want to make Pending?"
            : "Are you sure?"
        }
        type={rowType}
        //    id={rowId}
      />
    </>
  );
}

export default OrganizationListTable;
