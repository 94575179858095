import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import useAxios from "../../../axiosinstance";
// import OrganizationApprovalTable from "./OrganizationApprovalTable";
import Dropdown from "../../custom/dropdown/Dropdown";
import "../Organization.css";
import "./Tabs.css";
// import OrganizationPendingTable from "./OrganizationPendingTable";
// import OrganizationRejectedTable from "./OrganizationRejectedTable";
import InputField from "../../custom/inputfield/InputField";
import OrganizationUserPatientTable from "./OrganizationUserPatientTable";
import OrganizationUserHcpTable from "./OrganizationUserHcpTable";
import OrganizationUserAdminTable from "./OrganizationUserAdminTable";
import invitePatientIcon from "../../../images/Invite Patient.svg";
import TableActionItems from "../../custom/table-action-items/TableActionItems";
import Message from "../../custom/toster/Message";
import { noteRefs } from "../../../redux/actions";
import OrganizationSecurityAdminTable from "./OrganizationSecurityAdminTable";

function OrgUserList({ orgName, editPlatformList, platform_id, allPlatData }) {


  console.log("editPlatformList , platform_id", editPlatformList, platform_id)

  console.log("organization_name2", orgName)
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const dispatch = useDispatch()
  const { akou } = useParams();

  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const loginType = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);

  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState("pending");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [sort, setSort] = useState("last_created");
  const [offset, setOffset] = useState(10);
  const [offsetApproval, setOffsetApproval] = useState(10);
  const [offsetReject, setOffsetReject] = useState(10);
  const [offsetSecretary, setOffsetSecretary] = useState(10);


  const [limit, setLimit] = useState(10);
  const [role, setRole] = useState("");
  const [akouId, setAkouId] = useState("");
  const [aLlOrgList, setALlOrgList] = useState([]);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [search, setSerach] = useState("")
  const [loader1, setloader1] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [loader4, setloader4] = useState(false);

  const [lastTypingTime, setLastTypingTime] = useState(null);
  const [orgUserPatientList, setOrgUserPatientList] = useState([]);
  const [orgUserHcpList, setOrgUserHcpList] = useState([]);
  const [orgUserAdminList, setOrgUserAdminList] = useState([]);
  const [orgUserSecurityAdminList, setOrgUSecurituyAdminList] = useState([]);

  const [totalData, setTotalData] = useState("")
  const [platFormData, setPlatFormData] = useState([]);
  const [assignAllPlatform, setAssignAllPlatform] = useState("")
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();

  const handleSelect = (key) => {
    setSerach('')
    if (key === "pending") {
      setKey(key);
      setOffset(10)

      // getTabData("");
    } else if (key === "rejected") {
      setKey(key);
      setOffsetReject(10)

      // getTabData("");
    } else if (key === "approved") {
      setKey(key);
      // getTabData("");
      setOffsetApproval(10)

    } else if (key === "security") {
      setKey(key);
      // getTabData("");
      setOffsetSecretary(10)

    }


  };
  useEffect(() => {
    const getAllOrgList = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/orgs?ak_id=${login.ak_id}`
        );

        const newData = response.data.data.map((item) => ({
          value: item.id,
          label: item.o_nm,
          plan_id: item.plan_id,
        }));
        // setAkouId(newData[0].value === undefined ? "" : newData[0].value);
        setALlOrgList(newData);
      } catch (err) {
        console.log("An error occurred while fetching data.");
      }
    };
    getAllOrgList();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    const getData = async () => {
      try {

        const response = await axiosInstance.current.get(
          `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=0&role=${1}&username=${search}&akou_id=${akou}`
        );
        if (response.status === 200) {
          setloader1(true)
          setOrgUserPatientList(response.data.data);
          setTotalData(response.data.totalCount)
        }
        // setTotaldata(response.data.totalCount)

      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };

    if (key === "pending") {
      getData();
    }
  }, [key, dataRefe]);


  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=0&role=${1}&username=${search}&akou_id=${akou}`
            );
            if (response.status === 200) {
              setloader1(true)
              setOrgUserPatientList(response.data.data);
              setTotalData(response.data.totalCount)
            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };
        if (key === "pending")
          getData();

      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [search])

  const addMoreData = async () => {
    setOffset(offset + 10);
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=${offset}&role=${1}&username=${search}&akou_id=${akou}`
      );
      const resdata = response.data.data;
      setTimeout(() => {
        setOrgUserPatientList(orgUserPatientList.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const fetchMoreData = () => {
    if (key === "pending")
      addMoreData();
  };







  useEffect(() => {
    const getApprovalData = async () => {
      try {

        const response = await axiosInstance.current.get(
          `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=0&role=${3}&username=${search}&akou_id=${akou}`
        );
        if (response.status === 200) {
          setloader2(true)
          setOrgUserHcpList(response.data.data);
          setTotalData(response.data.totalCount)
        }
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (key === "approved")
      getApprovalData();
  }, [dataRefe, key]);





  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getApprovalData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=0&role=${3}&username=${search}&akou_id=${akou}`
            );
            if (response.status === 200) {
              setloader2(true)
              setOrgUserHcpList(response.data.data);
              setTotalData(response.data.totalCount)
            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };
        if (key === "approved")
          getApprovalData();
      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [search])





  const addMoreApprovalData = async () => {
    setOffsetApproval(offsetApproval + 10);
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=${offsetApproval}&role=${3}&username=${search}&akou_id=${akou}`
      );
      const resdata = response.data.data;
      console.log("data", response.data.data);
      setTimeout(() => {
        setOrgUserHcpList(orgUserHcpList.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const fetchMoreApprovalData = () => {
    if (key === "approved")
      addMoreApprovalData();
  };

console.log("keyyyyy",key)







  useEffect(() => {
    const getRejectedData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=0&role=${2}&username=${search}&akou_id=${akou}`
        );
        if (response.status === 200) {
          setloader3(true)
          setOrgUserAdminList(response.data.data);
          setTotalData(response.data.totalCount)
        }
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    if (key === "rejected")
      getRejectedData();
  }, [dataRefe, key]);



  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getRejectedData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=0&role=${2}&username=${search}&akou_id=${akou}`
            );
            if (response.status === 200) {
              setloader3(true)
              setOrgUserAdminList(response.data.data);
              setTotalData(response.data.totalCount)
            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };
        if (key === "rejected")
          getRejectedData();

      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [search])


  const addMoreRejectedData = async () => {
    setOffsetReject(offsetReject + 10);
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=${offsetReject}&role=${2}&username=${search}&akou_id=${akou}`
      );
      const resdata = response.data.data;
      console.log("data", response.data.data);
      setTimeout(() => {
        setOrgUserAdminList(orgUserAdminList.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const fetchMoreRejectedData = () => {
    if (key === "rejected")
      addMoreRejectedData();
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#D9D4E6" : "white",
      color: state.isSelected ? "#3f2783" : "#3f2783",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "rgba(63,39,131,.7)", // Set the color for the placeholder
    }),
  };


  const advsearch = (e) => {
    setLastTypingTime(new Date().getTime())
    setSerach(e)
  }

  useEffect(() => {
    if (search.length === 0) {
      setOffset(10)
      setOffsetApproval(10);
      setOffsetReject(10);
    }


  }, [search])



  useEffect(() => {
    const getPlatFormData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/getPlatform`
        );
        // setPlatFormData(response.data.data);
        let data = response.data.data.map((item) => {
          return {
            value: item.plat_id,
            label: item.name
          }
        })
        setPlatFormData(data)
      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };
    getPlatFormData();
  }, []);


  const handleAllPlatform = (e) => {
    const plat_value = e !== null ? e : "";
    setAssignAllPlatform(plat_value)

    console.log("plat_value", plat_value)

    if (plat_value) {
      axiosInstance.current.put(
        `extorg/users/update_all_platform?akou_id=${akou}&ak_id=${loginType.ak_id}&plat_id=${plat_value.value}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        setMessage("Platform Updated For HCP's")
        setMessageType("success");
        dispatch(noteRefs(new Date().getSeconds()))

        setTimeout(() => {
          setMessage(false)
        }, 2000);
      }).catch((err) => {
        dispatch(noteRefs(new Date().getSeconds()))
        setMessage("Platform Not Updated For HCP's")
        setMessageType("error");
      });
    }




  }


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    const getData = async () => {
      try {

        const response = await axiosInstance.current.get(
          `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=0&role=${4}&username=${search}&akou_id=${akou}`
        );
        if (response.status === 200) {
          setloader4(true)
          setOrgUSecurituyAdminList(response.data.data);
          setTotalData(response.data.totalCount)
        }
        // setTotaldata(response.data.totalCount)

      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };

    if (key === "security") {
      getData();
    }
  }, [key, dataRefe]);




  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        const getData = async () => {
          try {
            const response = await axiosInstance.current.get(
              `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=0&role=${4}&username=${search}&akou_id=${akou}`
            );
            if (response.status === 200) {
              setloader4(true)
              setOrgUSecurituyAdminList(response.data.data);
              setTotalData(response.data.totalCount)
            }
          } catch (error) {
            console.log("An error occurred while fetching data.");
          }
        };
        if (key === "security")
          getData();

      }, 1000);
      return () => clearTimeout(timer)
    }
  }, [search])

  const addMoreDataSecurity = async () => {
   setOffsetSecretary(offsetSecretary + 10);
    try {
      const response = await axiosInstance.current.get(
        `extorg/admin/get_all_users_list_of_org?limit=${limit}&offset=${offsetSecretary}&role=${4}&username=${search}&akou_id=${akou}`
      );
      const resdata = response.data.data;
      setTimeout(() => {
        setOrgUSecurituyAdminList(orgUserSecurityAdminList.concat(resdata));
      }, 500);
    } catch (error) {
      console.log("An error occurred while fetching data.");
    }
  };
  const fetchMoreDataSecurity = () => {
    if (key === "security")
    addMoreDataSecurity();
  };






  

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {message && <Message message={message} type={messageType} />}
          <div className="approve_org__list edit_user_org">
            <div className="panel">
              <div className="panel-heading  pannel_pd pt_20">
                <div className="row">
                  <div className="col-sm-12 col-md-12 d-flex">

                    <div className="">
                      <h4 className="mb-3 ml_25">{"List Of Users"}
                      </h4>
                      <p className="mb_30 ml_25">
                        {totalData} {totalData > 1 ? "Results" : "Result"}
                      </p>
                    </div>

                    <div className="ms-auto">
                      <div className="Custom_re_se_drop">
                        <InputField
                          styleClass="search-org"
                          value={search}
                          type="text"
                          name="firstName"
                          placeholder="Search Keyword..."
                          onChange={advsearch}

                        />
                      </div>

                      <div className="Custom_re_se_drop ms-3">
                        <Select
                          className="basic-single_org"
                          classNamePrefix="select"
                          defaultInputValue={assignAllPlatform}
                          value={assignAllPlatform}
                          isSearchable={isSearchable}
                          isClearable={isClearable}
                          onChange={handleAllPlatform}
                          name="label"
                          options={allPlatData}
                          styles={customStyles}
                          placeholder="All Platform"
                        />

                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div className="panel-body p_25">
                <div className="edittia">
                  <div>
                    <TableActionItems
                      viewAction="false"
                      // editAction="true"
                      feedbackAction="false"
                      // deleteAction="true"
                      addActionPopup={loginType.type === 6 ? "true" : "false"}
                      addActionHcpPopup="true"
                      addActionPatientPopup="true"
                      addSecurityAdmin="true"
                      arrowAction="false"
                      // rowName={row.row.original.org_id}
                      rowId={akou}
                      // rowData={actionData}
                      rowType="orgList"
                      // notetitle={row.row.original.ea_name}
                      // rowOrgId={row.row.original.org_name}
                      orgId={akou}
                      organization_id={akou}
                      orgListOrgName={orgName}
                      editPlatformList={editPlatformList}
                    />
                  </div>
                </div>
                <>

                  <Tabs
                    id="org-user-list-tabs"
                    activeKey={key}
                    onSelect={handleSelect}
                    // onSelect={(k) => setKey(k)}
                    className="mb-1"
                  >
                    <Tab eventKey="pending" title="Patient">

                      {!loader1 ? <div className="no_data">
                        loading...
                      </div> : (
                        <>
                          {
                            orgUserPatientList.length > 0 ? (
                              <OrganizationUserPatientTable
                                usersData={orgUserPatientList}
                                updatePagination={fetchMoreData}
                                orgId={akouId}
                                totalData={totalData}
                              />
                            ) : (
                              <div className="no_data">No data found</div>
                            )}

                        </>
                      )
                      }
                    </Tab>
                    <Tab eventKey="approved" title="HCP">

                      {!loader2 ? <div className="no_data">
                        loading...
                      </div> : (
                        <>
                          {
                            orgUserHcpList.length > 0 ? (
                              <OrganizationUserHcpTable
                                usersData={orgUserHcpList}
                                updatePagination={fetchMoreApprovalData}
                                orgId={akou}
                                platFormData={platFormData}
                                totalData={totalData}
                                orgName={orgName}
                                platform_id={platform_id}
                              />
                            ) : (
                              <div className="no_data">No data found</div>
                            )}

                        </>
                      )
                      }
                    </Tab>

                    {loginType.type === 6 &&
                      <Tab eventKey="rejected" title="Org Admin">

                        {!loader3 ? <div className="no_data">
                          loading...
                        </div> : (
                          <>
                            {
                              orgUserAdminList.length > 0 ? (
                                <OrganizationUserAdminTable
                                  usersData={orgUserAdminList}
                                  updatePagination={fetchMoreRejectedData}
                                  orgId={akouId}
                                  totalData={totalData}
                                />
                              ) : (
                                <div className="no_data">No data found</div>
                              )}

                          </>
                        )
                        }
                      </Tab>
                    }

                    <Tab eventKey="security" title="Secretary">

                      {!loader4 ? <div className="no_data">
                        loading...
                      </div> : (
                        <>
                          {
                            orgUserSecurityAdminList.length > 0 ? (
                              <OrganizationSecurityAdminTable
                                usersData={orgUserSecurityAdminList}
                                updatePagination={fetchMoreDataSecurity}
                                orgId={akouId}
                                totalData={totalData}
                              />
                            ) : (
                              <div className="no_data">No data found</div>
                            )}

                        </>
                      )
                      }
                    </Tab>
                  </Tabs>

                </>
                <></>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default OrgUserList;