import React, { useState ,useEffect} from "react";
import { useTable } from "react-table";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
import Confirmation from "../custom/confirmation-popup/ConfirmPopup";
import useAxios from "../../axiosinstance";
import { noteRefs } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Message from "../custom/toster/Message";
import InfiniteScroll from "react-infinite-scroll-component";
function OrganizationListTable({ orgData, updatePagination,totaldata }) {
  const login = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
    false
  );
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [rowType, setRowType] = useState("orgStausApprove");
  const [approveStatus, setApproveStatus] = useState("");
  const [orgId, setOrgId] = useState("");

  const [platFormData, setPlatFormData] = useState([]);

  useEffect(() => {
    const getPlatFormData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extorg/admin/getPlatform`
        );
        if (response.status === 200) {
          // setPlatFormData(response.data.data);
          let data = response.data.data.map((item) => {
            return {
              value: item.plat_id,
              label: item.name
            }
          })
          setPlatFormData(data)
        }

      } catch (error) {
        console.log("An error occurred while fetching data.");
      }
    };

      getPlatFormData();
    

  }, []);
  console.log(platFormData,"mrinnnplatFormData");
  const actionData = {
    view_url: "edit-organization-details",
    edit_url: "edit-organization-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = orgData;

  const handleStatusChange = async (status, org_id) => {
    setDisplayConfirmationModal(true);
    setApproveStatus(status);
    setOrgId(org_id);
    console.log("status", status);
  }
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const approveRequest = async () => {


    console.log("Status organization--", approveStatus);
    let s;
    if (Number(approveStatus) === 1) {
      s = -1
    } else {
      s = 1
    }
    const json = JSON.stringify({
      status: s,
    });
    let res = await axiosInstance.current.patch(
      `extorg/org/update_organization_status/${orgId}`, json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setMessage("Organization Approved");
      setMessageType("success");
      dispatch(noteRefs(new Date().getSeconds()));
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err) => {
      setMessage("Organization Not Approved")
      setMessageType("error");
    });
    setDisplayConfirmationModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "1",
        headerClassName: "col-img",
        className: "col-img",
        accessor: "name",
        Cell: ({row}) => (
          <span>
            <label className="user-letter small">
              {row.original.name.charAt(0).toUpperCase()}
            </label>
          </span>
        ),
      },
      {
        Header: "Organization ID",
        accessor: "id",
      },
      {
        Header: "Organization Name",
        accessor: "name",
      },
      {
        Header: "Speciality Name",
        accessor: 'speciality',
        Cell: ({ row }) => {
          return (row.original.speciality.length===0 ? "N/A":row.original.speciality.join(", "))
        },
      },
      {
        Header: "Status",
        accessor: "status",
        headerClassName: "col-status",
        className: "col-status",
        Cell: (row) => (
          <span>
            <button className={`badge bdg_btn ${row.row.original.status === 1 ? "badg_active" :  row.row.original.status === 0 ? "badg_pending" : "badg_inactive"}`}>
              {row.row.original.status === 1 ? "Active" : row.row.original.status === 0 ? "Pending" : "Suspended"}
            </button>
          </span>
        ),
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
            <TableActionItems
              viewAction="false"
              editAction="false"
              feedbackAction="false"
              deleteAction="false"
              addActionPopup="false"
              addActionHcpPopup="true"
              addActionPatientPopup="true"
              addSecurityAdmin="true"
              arrowAction="false"
              rowName={row.row.original.id}
              rowId={row.row.original.id}
              rowData={actionData}
              rowType="orgList"
              notetitle={row.row.original.name}
              rowOrgId={row.row.original.name}
              orgId={row.row.original.id}
              org_id={row.row.original.id}
              orgViewAction={true}
              orgListOrgName={row.row.original.name}
              platFormData={platFormData}
              platForm={row.row.original.platform}
              specility={row.row?.original?.specility}

            />
          </span>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          // loader={<TableSpinner />}
          loader={ orgData.length >= 25 && orgData.length !== totaldata  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      <Confirmation
        showModal={displayConfirmationModal}
        confirmModal={approveRequest}
        hideModal={hideConfirmationModal}
        message={"Are you sure want to Approve?"}
        type={rowType}
      //    id={rowId}
      />
    </>
  );
}

export default OrganizationListTable;
