import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTable } from "react-table";
import TableSpinner from "../../custom/spinner/TableSpinner";
import TableActionItems from "../../custom/table-action-items/TableActionItems";
import InfiniteScroll from "react-infinite-scroll-component";
import Switch from "../../custom/switch/Switch";
import { noteRefs } from "../../../redux/actions";
import useAxios from "../../../axiosinstance";
import Message from "../../custom/toster/Message";
import Select from 'react-select';
import SelectPlatForm from "./SelectPlatForm";


function OrganizationUserHcpTable({ usersData, updatePagination, orgId, platFormData ,totalData,orgName,platform_id}) {
  console.log("platform_idplatform_id",platform_id)

  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const loginType = useSelector(
    (state) =>
      state.mainReducer.loginData.arr && state.mainReducer.loginData.arr[0]
  );
  console.log("loginType", loginType.ak_id)
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [editPlatformList, setEditPlatFormList] = useState([])
  const [options, setOptions] = useState([])
  const [doc_id, setDocId] = useState("")


  const actionData = {
    view_url: "patient-view",
    edit_url: "edit-user-details",
    delete_url: "",
  };
  //   function handleEdit(row) {
  //     console.log(row);
  //   }
  const data = usersData;



  const handleSwitchChange = (status, id, sta) => {
    let s;
    if (status === 1) {
      s = 0
    } else {
      s = 1
    }
    console.log("status, id", status, id)

    axiosInstance.current.put(
      `extorg/users/updatePractitionerStatus/${loginType.ak_id}/${id}?practitioner=${s}&org_id=${orgId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setMessage("Practitioner Updated")
      setMessageType("success");
      dispatch(noteRefs(new Date().getSeconds()))
      setTimeout(() => {
        setMessage(false)
      }, 2000);
    }).catch((err) => {
      dispatch(noteRefs(new Date().getSeconds()))
      setMessage("Practitioner Not Updated")
      setMessageType("error");
    });

  };



  useEffect(() => {
    // Extract platform values from userData and create an array
    const platformValues = usersData.map((user) => user.platform);
    // Remove duplicates (if needed)
    const uniquePlatformValues = [...new Set(platformValues)];
    // Set the unique platform values to the state
    setEditPlatFormList(uniquePlatformValues);
  }, [usersData]);


  console.log("options", options)





  // useEffect(() => {
  //   if (options) {
  //     hanldeCall()
  //   }

  // }, [options]);

  console.log("optionss", options)



  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "1",
        headerClassName: "col-img",
        className: "col-img",
        accessor: "firstname",
        Cell: (row) => (
          <span>
            <label className="user-letter small">
              {row.value && row.value.trim() !== '' ? row.value.charAt(0).toUpperCase() : 'A'}
            </label>
          </span>
        ),
      },
      {
        Header: "User ID",
        accessor: "ak_id",
      },
      {
        Header: "User Name",
        accessor: "hcpName",
        Cell: (row) => {
          const { firstname, lastname } = row.row.original;

          let fullName = "Not Yet Joined";

          if (
            (firstname || lastname) &&
            firstname !== null && lastname !== null &&
            firstname !== undefined && lastname !== undefined
          ) {
            fullName = `${firstname || ""} ${lastname || ""}`.trim();
          }

          return <span>{fullName}</span>;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-lowercase",
      },
      {
        Header: "Account Status",
        accessor: "account_status",
        headerClassName: "col-acstatus",
        className: "col-acstatus",
        Cell: (row) => (
          <>
            {row.row.original.account_status === 1 ? (
              <span>Active</span>
            ) : (
              <span>InActive</span>
            )}
          </>
        ),
      },
      {
        Header: "Practitioner",
        accessor: "practitioner",
        headerClassName: "col-acstatus",
        className: "col-acstatus",
        Cell: (row) => (
          //  console.log("row", row.row.original.sno),
          <span>
            <Switch
              name={`on-off-switch${row.row.original.ak_id}`}
              tabIndex={1}
              checked={row.row.original.practitioner}
              onChange={(e) => handleSwitchChange(row.row.original.practitioner, row.row.original.ak_id)}
            />
          </span>
        ),
      },
      {
        Header: "Quick Code",
        accessor: "quick_code",
        headerClassName: "col-acstatus",
        className: "col-acstatus",
        Cell: (row) => (
          <>
            <span>{row.row.original.quick_code}</span>
          </>
        ),
      },
      {
        Header: "Platforms",
        accessor: "platform",
        headerClassName: "col-acstatus",
        className: "col-acstatus",
        Cell: (row) => (
          <span>
            <SelectPlatForm
              platFormData={platFormData}
              editPlatformList={row.row.original.platform}
              setOptions={setOptions}
              doctor_id={row.row.original.ak_id}
              org_id={orgId}
              ak_id={loginType.ak_id}
              email={row.row.original.email}
              orgName={orgName}
              platform_id={platform_id}
            />

          </span>
        ),
      },


    ],
    [selectedOption]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });
  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div className="tableContainer" id="rpScrollableDiv2323223">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv2323223"
          // loader={<TableSpinner />}
          loader={ usersData.length >= 10 && usersData.length !== totalData  ? <TableSpinner /> : null}

        >
          <table {...getTableProps()} className="custom-tbl table5">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default OrganizationUserHcpTable;
